import React, { useState } from 'react'
import { Chart, GoogleChartWrapper } from 'react-google-charts'
import type {
  GoogleDataTableColumn,
  GoogleDataTableRow,
  ChartWrapperOptions,
} from 'react-google-charts'
import {
  CHART_COLORS,
  DEFAULT_OPTIONS,
  STYLE_COLUMN,
  TOOLTIP_COLUMN,
} from './consts'
import { Box } from '@mui/material'
import { CustomTooltip } from './tooltip'
import { renderToStaticMarkup } from 'react-dom/server'
import Typography from '@mui/material/Typography'
import { PreloaderBlock } from '@components-simple/preloaders'
import { ObjHelper, TimeHelper } from '@lib/helpers'
import { adjustLabelPositions } from '@features/common/entropy-graph/utils'

interface Props {
  columns: Array<GoogleDataTableColumn>
  rows: Array<GoogleDataTableRow>
  isLoading: boolean
  options?: ChartWrapperOptions['options']
  onSelectPoint?: (row: GoogleDataTableRow) => void
  // This prop is used to determine if we should use dynamic ticks, which is used to avoid duplication of the same date (also useful when we do not need to render ticks where files count is 0)
  // But for time granularity like "hour" we need to render all ticks, otherwise we will have a gap in the graph
  shouldUseDynamicTicks?: boolean
}

function createGraphData(
  columns: Array<GoogleDataTableColumn>,
  rows: Array<GoogleDataTableRow>,
  selectedRowIndex: number | null
) {
  let previousFilesCount: null | number = null

  const rowsWithTooltip = rows.reduce((acc, row, index) => {
    const date = row[0] as Date
    const files = row[1] as number
    const isSelectedRow = selectedRowIndex === index

    if (files === 0) {
      acc.push([
        date,
        // We need to pass null to render empty cell correctly
        // @ts-ignore
        null,
        renderToStaticMarkup(
          <CustomTooltip time={date} files={files} filesDifference={null} />
        ),
        isSelectedRow
          ? CHART_COLORS.SELECTED_STATE
          : CHART_COLORS.DEFAULT_STATE,
      ])

      return acc
    }

    const filesDiff =
      previousFilesCount !== null ? files - previousFilesCount : null

    previousFilesCount = files

    acc.push([
      ...row,
      renderToStaticMarkup(
        <CustomTooltip time={date} files={files} filesDifference={filesDiff} />
      ),
      isSelectedRow ? CHART_COLORS.SELECTED_STATE : CHART_COLORS.DEFAULT_STATE,
    ])

    return acc
  }, [] as Array<GoogleDataTableRow>)

  return [[...columns, TOOLTIP_COLUMN, STYLE_COLUMN], ...rowsWithTooltip]
}

function setGraphOptions(
  rows: Array<GoogleDataTableRow>,
  options?: ChartWrapperOptions['options'],
  shouldUseDynamicTicks?: boolean
): ChartWrapperOptions['options'] {
  // We create the dynamic tricks to avoid duplication of the same date
  // This idea I saw in Google repo: https://github.com/google/site-kit-wp/pull/3735/files#diff-8db3644a1aeae1841cfb8a797322154c0c1c45287430d12c92a782921e2cb43cR87
  const dateTicks = rows
    .filter((row) => {
      const files = row[1] as number

      return files !== 0
    })
    .map((row) => row[0] as Date)

  // Handle single non-zero value case to center it on the graph
  const getCenteredDateTicks = () => {
    const oneDate = dateTicks[0]

    if (!oneDate || dateTicks.length > 2) {
      return dateTicks
    }

    return [
      TimeHelper.getDateWithTimezone(oneDate.getTime())
        .subtract(1, 'day')
        .toDate(), // One day before
      oneDate,
      TimeHelper.getDateWithTimezone(oneDate.getTime()).add(1, 'day').toDate(), // One day after
    ]
  }

  const DEFAULT_OPTIONS_WITH_TICKS = {
    ...DEFAULT_OPTIONS,
    hAxis: {
      ...DEFAULT_OPTIONS.hAxis,
      ticks: shouldUseDynamicTicks ? getCenteredDateTicks() : undefined,
    },
  }

  if (!options) {
    return DEFAULT_OPTIONS_WITH_TICKS
  }

  return ObjHelper.mergeObjects(DEFAULT_OPTIONS_WITH_TICKS, options)
}

export default function EntropyGraph({
  rows,
  columns,
  options,
  onSelectPoint,
  isLoading,
  shouldUseDynamicTicks = true,
}: Props) {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)

  const memoizedData = createGraphData(columns, rows, selectedRowIndex)

  const memoizedOptions = setGraphOptions(rows, options, shouldUseDynamicTicks)

  const handleSelectPoint = (chartWrapper: GoogleChartWrapper) => {
    const chart = chartWrapper.getChart()
    const selection = chart.getSelection()

    if (!selection.length) {
      return
    }

    const selectedItem = selection[0]

    if (!selectedItem) {
      return
    }

    const selectedRow = rows[selectedItem.row]

    if (!selectedRow || !onSelectPoint) {
      return
    }

    setSelectedRowIndex((prevSelectedRowIndex) => {
      const isSameRow = prevSelectedRowIndex === selectedItem.row

      if (isSameRow) {
        onSelectPoint([])
        return null
      }

      onSelectPoint(selectedRow)
      return selectedItem.row
    })
  }

  if (isLoading) {
    return (
      <Box paddingY="16px">
        <PreloaderBlock show />
      </Box>
    )
  }

  if ((rows.length === 0 && !isLoading) || rows.every((row) => row[1] === 0)) {
    return (
      <Box padding={4}>
        <Typography
          width="100%"
          textAlign="center"
          variant="h5"
          color="textSecondary"
        >
          No Scan Data for the selected time range
        </Typography>
      </Box>
    )
  }

  return (
    <Box paddingY="16px">
      <Chart
        chartType="LineChart"
        data={memoizedData}
        loader={<PreloaderBlock show />}
        options={memoizedOptions}
        chartEvents={[
          {
            eventName: 'ready',
            callback: ({ chartWrapper }) => adjustLabelPositions(chartWrapper),
          },
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => handleSelectPoint(chartWrapper),
          },
        ]}
      />
    </Box>
  )
}
